const currencyFormat = (value, fractionDigists = 2) => {
  if (typeof value !== 'number') {
    return '';
  }
  return value.toLocaleString('sk', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
    useGrouping: true,
    minimumFractionDigits: fractionDigists,
    maximumFractionDigits: fractionDigists,
  });
};

export default currencyFormat;
