<template>
  <ModalWindow
    :name="name"
    title="Súhlas so spracúvaním osobných údajov na marketingové účely"
  >
    <!-- eslint-disable max-len, no-irregular-whitespace -->
    <InfoBox style="margin:0.5rem 0 0 0">Neposkytnutie súhlasu so spracovaním osobných údajov neznamená automatické odvolanie súhlasu udeleného v minulosti.</InfoBox>
    <p>Súhlasím, aby Generali Poisťovňa, pobočka poisťovne z iného členského štátu, IČO: 54 228 573, so sídlom Lamačská cesta 3/A, 841 04 Bratislava, zapísaná v Obchodnom registri Mestského súdu Bratislava III, oddiel: Po, vložka č.: 8774/B, organizačná zložka podniku zahraničnej osoby, prostredníctvom ktorej v Slovenskej republike podniká Generali Česká pojišťovna, a.s., IČO: 452 72 956, so sídlom Spálená 75/16, Nové Město, 110 00 Praha 1, Česká republika, zapísaná v obchodnom registri Mestského súdu v Prahe, oddiel B, vložka č. 1464, (ďalej aj ako „Generali Poisťovňa“), spracúvala moje osobné údaje v rozsahu telefónne číslo, e-mailová adresa a vek a kontaktovala ma na účely ponúkania produktov a služieb Generali Poisťovne a poskytovania informácií o aktivitách Generali Poisťovne, a to počas trvania zmluvného vzťahu s Generali Poisťovňou a po dobu 5 rokov od ukončenia platnosti všetkých mojich zmluvných vzťahov s Generali Poisťovňou. V prípade ak poskytnem osobné údaje Generali Poisťovni, ale s Generali Poisťovňou mi nevznikne žiadny zmluvný vzťah (napr. v prípade vypracovania návrhu poistnej zmluvy alebo účasti v súťaži), platí tento súhlas po dobu 3 rokov od jeho udelenia.</p>
    <p>Beriem na vedomie, že tento súhlas so spracúvaním osobných údajov môžem kedykoľvek odvolať zaslaním písomného odvolania na adresu sídla Generali Poisťovne alebo odvolania prostredníctvom webového sídla Generali Poisťovne. Podrobné informácie o mojich právach ako dotknutej osoby a spracúvaní osobných údajov, sú uvedené na webovom sídle Generali Poisťovne: www.generali.sk a na pobočkách Generali Poisťovne.</p>
    <p>Zároveň súhlasím s tým, že mi marketingové informácie môžu byť poskytované aj prostredníctvom automatických volacích a komunikačných systémov bez ľudského zásahu alebo elektronickej pošty vrátane služby krátkych správ v zmysle osobitných právnych predpisov (napr. zákon o reklame, zákon o elektronických komunikáciách).</p>
    <!-- eslint-enable max-len, no-irregular-whitespace -->
    <template #footer>
      <BaseButton
        variant="primary"
        @click="handleAgree()"
      >
        Súhlasím
      </BaseButton>
      <BaseButton
        variant="secondary"
        @click="handleDisagree()"
      >
        Nesúhlasím
      </BaseButton>
    </template>
  </ModalWindow>
</template>

<script>
import ModalWindow from '@gds/components/ModalWindow';
import BaseButton from '@gds/components/BaseButton';
import InfoBox from '@gds/components/InfoBox';

export default {
  name: 'ModalMarketingAgreement',
  components: {
    ModalWindow,
    BaseButton,
    InfoBox,
  },
  props: {
    name: {
      type: String,
      default: 'marketingAgreement',
    },
  },
  methods: {
    handleAgree() {
      this.$emit('agree');
      this.$modal.hide(this.name);
    },
    handleDisagree() {
      this.$emit('disagree');
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style>

</style>
