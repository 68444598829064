<template>
  <div
    class="button-checkbox"
  >
    <input
      type="checkbox"
      class="real"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
    >
    <div
      class="fake"
      :class="{ '-checked': value }"
    ></div>
  </div>
</template>

<script>
import './ButtonCheckbox.scss';

export default {
  name: 'ButtonCheckbox',
  props: {
    value: Boolean,
  },
};
</script>
