<template>
  <InfoBox
    variant="danger"
    size="small"
  >
    <h4 style="margin-top: 0">Výpis chýb:</h4>
    <ul style="margin-bottom: 0" v-if="Array.isArray(errors)">
      <li
        v-for="(error, i) in errors"
        :key="i"
        v-text="error"
      />
    </ul>
    <span v-else>{{ errors }}</span>
  </InfoBox>
</template>

<script>
import InfoBox from '@gds/components/InfoBox';

export default {
  props: {
    errors: [Array, Object],
  },
  components: {
    InfoBox,
  },
};
</script>

<style>

</style>
