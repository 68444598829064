<template>
  <div>
    <div class="container">
      <InfoBox
        v-if="isSaveError"
        variant="danger"
        :icon="icons.error"
        ref="saveError"
      >
        Pri ukladaní zmluvy nastala chyba. Prosím, obráťte sa na naše kontaktné centrum – 02/38 11 11 16.
      </InfoBox>
      <ErrorList
        v-if="showErrorDetail"
        :errors="$store.state.calculation.final.errors"
      />

      <DescriptionList
        :items="summaryDetails"
      />
    </div>

    <BaseHeading size="small">
      Vaše údaje
    </BaseHeading>

    <div class="container">
      <DescriptionList
        :items="personalDetails"
      />
    </div>

    <BaseHeading size="small">
      Súhlasy
    </BaseHeading>

    <div class="container">
      <FormGroup>
        <InputCheckbox
          v-model="agreements.marketingAgreement"
          :infoButton="true"
          @info-button-click="$modal.show('marketingAgreement')"
        >
          Súhlasím so spracovaním osobných údajov na marketingové účely
        </InputCheckbox>
      </FormGroup>

      <FormGroup
        :rules="[$v.rules.is({ compare: true, message: 'Pred uzatvorením zmluvy je potrebné oboznámiť sa s\xa0uvedenými dokumentmi a vyjadriť svoj súhlas.' })]"
      >
        <InputCheckbox
          v-model="agreements.docs"
        >
          Potvrdzujem, že som sa oboznámil a{{'\xa0'}}súhlasím s{{'\xa0'}}nasledujúcimi dokumentmi:
        </InputCheckbox>
      </FormGroup>

      <DocumentsList
        afterCheckbox
        :docs="docsToAccept"
      />

      <FormGroup
        :rules="[$v.rules.is({ compare: true, message: 'Bez vášho súhlasu nemôže byť uzatvorená poistná zmluva.' })]"
      >
        <InputCheckbox
          v-model="agreements.customerStatement"
          :infoButton="true"
          @info-button-click="$modal.show('customerStatement')"
        >
          Zaškrtnutím vyjadrujem súhlas s vyhlásením poistníka
        </InputCheckbox>

      </FormGroup>

      <InfoBox
        variant="info"
        size="small"
        :icon="icons.file"
      >
        Všetky písomnosti vám budeme zasielať na váš e&#8209;mail.
      </InfoBox>

      <InfoBox
        v-if="['L', 'XL'].includes(productProps.package)"
        variant="info"
        size="small"
        :icon="icons.mobilePhone"
      >
        Poistenie vám platí až po online obhliadke. Nemusíte nikam chodiť, stačí vám mobil:
        <ol>
          <li>Po dokončení objednávky vám pošleme SMS s odkazom</li>
          <li>Kliknete na odkaz a postupujete podľa inštrukcií</li>
          <li>Celým procesom vás prevedieme</li>
          <li>Urobíte pár fotiek a odošlete jedným klikom</li>
        </ol>
      </InfoBox>

      <ModalMarketingAgreement
        name="marketingAgreement"
        @agree="agreements.marketingAgreement = true"
        @disagree="agreements.marketingAgreement = false"
      />

      <ModalWindow
        name="customerStatement"
        title="Vyhlásenie"
      >
        <p>Prijatím tohto návrhu na uzavretie poistnej zmluvy potvrdzujem, že som uviedol a zodpovedal pravdivo, úplne a podľa svojho najlepšieho vedomia všetky zadané otázky a údaje a že som nezamlčal žiadnu skutočnosť rozhodujúcu pre uzavretie poistnej zmluvy.</p>
        <p>Ďalej potvrdzujem, že mi pred uzavretím poistnej zmluvy boli písomne poskytnuté informácie v zmysle Zákona o poisťovníctve v platnom a účinnom znení (t. j. Informácie o dôležitých zmluvných podmienkach uzatváranej poistnej zmluvy prostredníctvom Informačného dokumentu o poistnom produkte).</p>
        <p>Prijatím tohto návrhu na uzavretie poistnej zmluvy beriem na vedomie, že poisťovateľ je oprávnený spracúvať osobné údaje dotknutých osôb v zmysle Zákona o poisťovníctve v platnom znení. Všeobecné informácie o spracúvaní osobných údajov poisťovateľom sú v Informáciách o spracúvaní osobných údajov, ktoré sú súčasťou zmluvnej dokumentácie. Podrobné informácie o spracúvaní osobných údajov, s ktorými som sa oboznámil/-a pred prijatím návrhu na uzavretie poistnej zmluvy, sú uvedené na webovom sídle poisťovateľa: www.generali.sk a na pobočkách poisťovateľa.</p>
        <template #footer>
          <BaseButton
            variant="primary"
            @click="agreements.customerStatement = true; $modal.hide('customerStatement')"
          >
            Súhlasím
          </BaseButton>
          <BaseButton
            variant="secondary"
            @click="agreements.customerStatement = false; $modal.hide('marketingAgreement')"
          >
            Nesúhlasím
          </BaseButton>
        </template>
      </ModalWindow>

      <LoadingOverlay
        v-if="isLoading"
      />
    </div>

  </div>
</template>

<script>
import { lightFormat } from 'date-fns';
import {
  faFileExport, faMobile, faExclamationCircle, faSmileWink,
} from '@fortawesome/pro-light-svg-icons';
import currencyFormat from '@gds/util/currencyFormat';
import scrollToElement from '@gds/util/scrollToElement';

import BaseHeading from '@gds/components/BaseHeading';
import BaseButton from '@gds/components/BaseButton';
import DescriptionList from '@gds/components/DescriptionList';
import FormGroup from '@gds/components/FormGroup';
import InputCheckbox from '@gds/components/InputCheckbox';
import InfoBox from '@gds/components/InfoBox';
import DocumentsList from '@gds/components/DocumentsList';
import ModalWindow from '@gds/components/ModalWindow';
import ModalMarketingAgreement from '@gds/common/calculators/ModalMarketingAgreement';
import LoadingOverlay from '@gds/common/calculators/LoadingOverlay';
import ErrorList from '@gds/common/calculators/ErrorList';

import { mapGetters } from 'vuex';

const translateFrequency = (frequency) => ({
  1: 'Ročná',
  2: 'Polročná',
  4: 'Štvrťročná',
  12: 'Mesačná',
}[frequency]);

const translateFrequencyPayment = (frequency) => ({
  1: 'Ročné poistné',
  2: 'Polročné poistné',
  4: 'Štvrťročné poistné',
  12: 'Mesačné poistné',
}[frequency]);

export default {
  components: {
    BaseHeading,
    BaseButton,
    DescriptionList,
    FormGroup,
    InputCheckbox,
    InfoBox,
    DocumentsList,
    ModalWindow,
    ModalMarketingAgreement,
    LoadingOverlay,
    ErrorList,
  },
  data() {
    return {
      isLoading: false,
      isSaveError: false,
      showErrorDetail: false,
      icons: {
        claimsDecrease: faSmileWink,
        file: faFileExport,
        mobilePhone: faMobile,
        error: faExclamationCircle,
      },
    };
  },
  computed: {
    ...mapGetters([
      'productProps',
      'currentPrice',
      'agreements',
      'customer',
      'customerFullName',
      'customerReadableDetails',
      'owner',
      'ownerFullName',
      'ownerReadableDetails',
      'vehicleReadableMakeModel',
      'vehicleReadableDetails',
    ]),
    summaryDetails() {
      return [
        {
          label: 'Vybrané poistné krytie',
          value: `Balík ${this.productProps.package}`,
        },
        {
          label: 'Začiatok poistenia',
          value: lightFormat(this.productProps.startDate, 'dd.MM.yyyy'),
          onEdit: () => { this.handleEdit('START_DATE'); },
        },
        {
          label: 'Frekvencia platieb',
          value: translateFrequency(this.productProps.frequency),
        },
        {
          label: translateFrequencyPayment(this.productProps.frequency),
          value: currencyFormat(this.currentPrice),
        },
      ];
    },
    personalDetails() {
      return [
        {
          label: 'Poistník',
          value: this.customerFullName,
          valueExpanded: this.customerReadableDetails,
          tooltipText: 'Poistník je osoba, ktorá uzatvára poistnú zmluvu. V tomto prípade ste to vy. Vozidlo však môžete poistiť len vtedy, ak ste jeho držiteľom.',
          onEdit: () => { this.handleEdit('CUSTOMER'); },
        },
        {
          label: 'Vlastník vozidla',
          value: this.ownerFullName,
          valueExpanded: this.ownerReadableDetails,
          onEdit: () => { this.handleEdit('OWNER'); },
        },
        {
          label: 'Vozidlo',
          value: this.vehicleReadableMakeModel,
          valueExpanded: this.vehicleReadableDetails,
          onEdit: () => { this.handleEdit('VEHICLE'); },
        },
        {
          label: 'Telefón',
          value: this.customer.phone.prefix + this.customer.phone.number,
          onEdit: () => { this.handleEdit('CONTACT'); },
        },
        {
          label: 'E-mail',
          value: this.customer.email,
          onEdit: () => { this.handleEdit('CONTACT'); },
        },
      ];
    },
    docsToAccept() {
      return [
        {
          title: 'Informačný dokument o poistnom produkte',
          url: 'https://files.obh.sk/pzp-docs-gc/ipid.pdf',
          external: true,
        },
        {
          title: 'Všeobecné poistné podmienky PZP',
          url: 'https://files.obh.sk/pzp-docs-gc/vpp.pdf',
          external: true,
        },
        {
          title: 'Poistné podmienky - asistenčné služby Klasik',
          url: 'https://files.obh.sk/pzp-docs-gc/opp-klasik.pdf',
          external: true,
        },
        {
          title: 'Podmienky pre doplnkové poistenia k PZP',
          url: 'https://files.obh.sk/pzp-docs-gc/pripoistenia.pdf',
          external: true,
        },
        this.productProps.package === 'XL'
          ? {
            title: 'Poistné podmienky - asistenčné služby Premium',
            url: 'https://files.obh.sk/pzp-docs-gc/asistencia-premium.pdf',
            external: true,
          } : null,
        ['XL', 'L'].includes(this.productProps.package)
          ? {
            title: 'Poistné podmienky pripoistení - balík L/XL',
            url: 'https://files.obh.sk/pzp-docs-gc/pripoistenia-l-xl.pdf',
            external: true,
          } : null,
        ['XL', 'L'].includes(this.productProps.package)
          ? {
            title: 'Oceňovacie tabuľky',
            url: 'https://files.obh.sk/pzp-docs-gc/ocenovacie-tabulky.pdf',
            external: true,
          } : null,
        {
          title: 'Podmienky uzavretia poistnej zmluvy na diaľku',
          url: 'https://files.obh.sk/pzp-docs-gc/informacie-na-dialku.pdf',
          external: true,
        },
        {
          title: 'Spracovanie osobných údajov',
          url: 'https://files.obh.sk/pzp-docs-gc/informacie-osobne-udaje.pdf',
          external: true,
        },
        {
          title: 'Poistné krytie sa nevzťahuje na tieto sankcionované krajiny',
          url: 'https://www.generali.sk/o-nas/medzinarodne-sankcie/',
          external: true,
        },
      ];
    },
  },
  methods: {
    async tryProceed() {
      if (this.$v.validator.check()) {
        this.isLoading = true;
        const saveStatus = await this.$store.dispatch('save', this.$apollo);
        if (saveStatus) {
          this.$v.validator.checkAndContinue(this);
        } else {
          this.isSaveError = true;
          this.showErrorDetail = !!process.env.VUE_APP_DEBUG_MODE;
          this.$nextTick(() => {
            scrollToElement({
              element: this.$refs.saveError.$el,
              offset: 10,
            });
          });
        }
        this.isLoading = false;
      }
    },
    handleEdit(event) {
      const { progress } = this.$store.state;
      const map = {
        START_DATE: '/5',
        CUSTOMER: '/4',
        OWNER: { path: '/5', hash: '#vlastnik' },
        VEHICLE: '/1',
        CONTACT: { path: '/4', hash: '#kontakt' },
      };
      const navigateTo = map[event];
      if (navigateTo) {
        progress.returnTo = 6;
        this.$router.push(navigateTo, () => { console.log('rerouted'); });
      }
    },
  },
  mounted() {
    this.$sendToCI();
    this.$store.dispatch('update', {
      apollo: this.$apollo,
    });
  },
};
</script>
