<template>
  <label class="input-checkbox">
    <ButtonCheckbox
      class="box"
      :value="value"
      @input="$emit('input', $event); $emit('change', $event);"
    />
    <div class="label">
      <slot/>
    </div>
    <InterfaceIcon
      v-if="infoButton"
      element="button"
      icon="info"
      size="medium"
      class="info"
      @click="$emit('info-button-click')"
    />
  </label>
</template>

<script>
import './InputCheckbox.scss';
import ButtonCheckbox from '@gds/subcomponents/ButtonCheckbox';
import InterfaceIcon from '@gds/components/InterfaceIcon';

export default {
  name: 'InputCheckbox',
  components: {
    ButtonCheckbox,
    InterfaceIcon,
  },
  props: {
    value: {
      type: Boolean,
    },
    infoButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
