<template>
  <div class="loading-overlay">
    <BaseSpinner
      :size="80"
      class="spinner"
    />
    <div class="animated-messages">
      <transition name="fade">
        <div
          class="message"
          :key="activeMessageIndex"
          v-text="messages[activeMessageIndex]"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@gds/components/BaseSpinner';

export default {
  components: {
    BaseSpinner,
  },
  props: {
    // Duration in milliseconds (ms)
    cycleDuration: {
      type: Number,
      default: 5000,
    },
    messages: {
      type: Array,
      default: () => [
        'Pripravujem údaje na zmluvu',
        'Registrujem zmluvu v systéme',
        'Vyhodnocujem vytvorenie obhliadky',
        'Vytváram zmluvu',
        'Spracovávam akceptáciu zmluvy',
        'Generujem zmluvnú dokumentáciu',
        'Vytváram automatickú korešpondenciu',
        'Pripravujem možnosti platby',
      ],
    },
  },
  data() {
    return {
      activeMessageIndex: 0,
      worker: null,
    };
  },
  methods: {
    startMessageCycle() {
      this.worker = setInterval(this.messageCycle, this.cycleDuration);
    },
    endMessageCycle() {
      clearInterval(this.worker);
    },
    messageCycle() {
      if (this.activeMessageIndex < this.messages.length - 1) {
        this.activeMessageIndex += 1;
      } else {
        this.endMessageCycle();
      }
    },
  },
  mounted() {
    this.startMessageCycle();
  },
  beforeDestroy() {
    this.endMessageCycle();
  },
};
</script>

<style lang="scss" scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 20px;
  background-color: rgba(black, 0.8);
  backdrop-filter: blur(5px);
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .spinner {
    margin-bottom: 30px;
  }
}

.animated-messages {
  width: 100%;
  font-size: 20px;
  height: 28px;
  position: relative;
  text-align: center;
  .message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .7s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter {
  transform: translateY(20px);
}
.fade-leave-to {
  transform: translateY(-20px);
}

</style>
